<template>
    <div class="backdrop">
        <div class="modal">

            <div class="modal__close">
                <span>Filtry</span>
                <i @click.self="closeModal()" class="fi-rr-cross"></i>
            </div>

            <div class="modal__loading" v-if="loading === true">
                <div class="loader"></div>
            </div>

            <div class="modal__filters" v-if="loading === false">
                <div class="modal__filters__category">
                    <div class="modal__filters__category__name">
                        <h4>Sortowanie</h4>
                    </div>
                    <div class="modal__filters__category__select">
                        <select v-model="chosenValues.sort">
                            <option value="asc">Cena rosnąco</option>
                            <option value="dsc">Cena malejąco</option>
                            <option value="alphabet">Alfabetycznie</option>
                        </select>
                    </div>
                </div>

                <div class="modal__filters__category">
                    <div class="modal__filters__category__name">
                        <h4>Podkategorie</h4>
                        <!-- <span>Zaznacz wszystkie</span> -->
                    </div>
                    <div class="modal__filters__category__noData" v-if="subcategories.length === 0">Brak opcji do wyboru</div>
                    <div class="modal__filters__category__filter" v-for="value in subcategories">
                        <div class="checkbox">
                            <input type="checkbox" :value="value" :id="value" name="check" @change="filterProductCount" v-model="chosenValues.subcat" />
                            <label :for="value"></label>
                        </div>
                        <span @click.prevent="toggleValue(chosenValues.subcat,value)">{{value === "" ? "Nie dotyczy" : value}}<span></span></span>
                    </div>
                </div>

                <div :class="{modal__filters__category: true, hide: ((option.categoryBucketName === 'screenSizesOptions' || option.categoryBucketName === 'displayTouchableOptions') && (aio !== true))}" v-for="option in options">
                        <div class="modal__filters__category__name">
                            <h4>{{option.name}}</h4>
                            <!-- <span>Zaznacz wszystkie</span> -->
                        </div>
                        <div class="modal__filters__category__filter" v-for="value in option.options">
                            <div class="checkbox" :key="checkboxIncrement" v-show="value !== ''">
                                <input type="checkbox" :value="value" :id="value + option.filterQueryTarget" name="check" @change="filterProductCount" v-model="chosenValues[option.filterQueryTarget]" />
                                <label :for="value + option.filterQueryTarget"></label>
                            </div>
                            
                            <span v-show="value !== ''" @click.prevent="toggleValue(chosenValues[option.filterQueryTarget],value)">{{value === "" ? "Nie dotyczy" : value}}<span></span></span>
                        </div>
                </div>

                <div class="modal__filters__category">
                    <div class="modal__filters__category__name">
                        <h4>Cena</h4>
                    </div>
                    <div class="modal__filters__category__priceBox">
                        <div class="modal__filters__category__priceBox__price" @click="focusInput('fromPrice')">
                            <input ref="fromPrice" placeholder="od" type="number" @change="filterProductCount" @blur="repairPrice('min',chosenValues.minPrice)" v-model="chosenValues.minPrice" />
                            <span>zł</span>
                        </div>
                        <span>-</span>
                        <div class="modal__filters__category__priceBox__price" @click="focusInput('toPrice')">
                            <input ref="toPrice" placeholder="do" type="number" @change="filterProductCount" @blur="repairPrice('max',chosenValues.maxPrice)" v-model="chosenValues.maxPrice" />
                            <span>zł</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal__save">
                <button v-show="!loading" @click.prevent="reset">Reset</button>
                <button v-show="!loading" @click.prevent="sendProducts" class="saveButton">Pokaż ({{filteredAmount}})</button>
            </div>
            
        </div>
    </div>
</template>
<script>
import {db,firebase} from '@/configs/firebase'
export default {
  name: "Filters",
  props: ['options','category','arrayOfRef','activeData'],
  data() {
    return {
        aio: true,
        filteredAmount: 0,
        categoryMaxPrice: 0,
        categoryMinPrice: 0,
        loading: false,
        subcategories: [],
        chosenValues: {
            sort: "asc",
            subcat: [],
            minPrice: 0,
            maxPrice: 0
        },
        refs: [],
        products: [],
        checkboxIncrement: 0,
    };
  },
  created()
  {
    console.log(this.options);
    console.log(this.category);
    console.log(this.arrayOfRef);
    console.log(this.activeData);
    this.checkIfAvailable();
  },
  methods: {
    reset()
    {
        this.$emit("resetFilter", {
        });
        this.closeModal();    
    },
    filterProductCount()
    {
        if(this.category === 'Komputery'){
            if(this.chosenValues["specs.casing.value"].includes('All in one')){
                this.aio = true
            }
            else{
                this.aio = false
            }
        }
        let filteredProducts = [];
        // Go with products one by one
        for(let i=0; i< this.products.length;i++)
        {
            let product = this.products[i];
            // Check if product is in specific specs
            if(this.chosenValues.subcat.includes(product.subcategory))
            {
                // Check price range
                if(product.basePrice>=this.chosenValues.minPrice && product.basePrice<=this.chosenValues.maxPrice)
                {
                    if(this.options.length>0)
                    {
                        
                        let amountOfOptions = this.options.length;
                        let optionsPassed = 0;
                        for(let y=0;y<amountOfOptions;y++)
                        {
                            let currentOption = this.options[y];
                            if(this.chosenValues[currentOption.filterQueryTarget] === undefined)
                            {
                               this.chosenValues[currentOption.filterQueryTarget] = []; 
                            }
                            if(this.chosenValues[currentOption.filterQueryTarget].includes(eval(`product.${currentOption.filterQueryTarget}`)))
                            {
                                optionsPassed++;
                            }
                        }
                        if(optionsPassed === amountOfOptions)
                        {
                        
                            filteredProducts.push(product);
                        }
                    }
                    else
                    {
                        filteredProducts.push(product);
                    }
                }
            }
        }
        this.filteredAmount = filteredProducts.length;
    },
    sendProducts()
    {
        let filteredProducts = [];
        // Go with products one by one
        for(let i=0; i< this.products.length;i++)
        {
            let product = this.products[i];
            // Check if product is in specific specs
            if(this.chosenValues.subcat.includes(product.subcategory))
            {
                // Check price range
                if(product.basePrice>=this.chosenValues.minPrice && product.basePrice<=this.chosenValues.maxPrice)
                {
                    if(this.options.length>0)
                    {
                        
                        let amountOfOptions = this.options.length;
                        let optionsPassed = 0;
                        for(let y=0;y<amountOfOptions;y++)
                        {
                            let currentOption = this.options[y];
                            if(this.chosenValues[currentOption.filterQueryTarget] === undefined)
                            {
                                this.chosenValues[currentOption.filterQueryTarget] = [];
                            }
                            if(this.chosenValues[currentOption.filterQueryTarget].includes(eval(`product.${currentOption.filterQueryTarget}`)))
                            {
                                optionsPassed++;
                            }
                        }
                        if(optionsPassed === amountOfOptions)
                        {
                        
                            filteredProducts.push(product);
                        }
                    }
                    else
                    {
                        filteredProducts.push(product);
                    }
                }
            }
        }
        filteredProducts.sort(function compareNumbers(a, b) {
            return a.basePrice - b.basePrice
        });
        if(this.chosenValues.sort === 'dsc')
        {
            filteredProducts.reverse();
        }
        if(this.chosenValues.sort === 'alphabet')
        {
            filteredProducts.sort(function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            })
        }
        const queryString = require('query-string');
        const stringified = queryString.stringify(this.chosenValues);
        if(stringified !== this.$route.query.filters){
            this.$router.replace({query: {filters: `${stringified}` }})
        }
        this.$emit("applyFilter", {
            toggled: true,
            products: filteredProducts,
            currentValues: this.chosenValues
        });
        this.closeModal();
    },
    repairPrice(type,value)
    {
        if(type === "min")
        {
           if(value.length === 0 || parseFloat(value) < 0)
           {
               this.chosenValues.minPrice = parseFloat(this.categoryMinPrice);
           } 
           else
           {
               this.chosenValues.minPrice = parseFloat(this.chosenValues.minPrice).toFixed(2)
           }
           
        }
        if(type === "max")
        {
           if(value.length === 0 || parseFloat(value) > this.categoryMaxPrice)
           {
               this.chosenValues.maxPrice = parseFloat(this.categoryMaxPrice);
           }
           else
           {
               this.chosenValues.maxPrice = parseFloat(this.chosenValues.maxPrice).toFixed(2)
           }
           
        }
    },
    // Toggle values by clicking label
    toggleValue(arrOfValues,val)
    {
        if(arrOfValues.includes(val))
        {
            const index = arrOfValues.indexOf(val);
            arrOfValues.splice(index,1);
        }
        else
        {
            arrOfValues.push(val);
        }
        this.checkboxIncrement++;
        this.filterProductCount();
    },
    // filters available options
    async checkIfAvailable()
    {
        this.loading = true;
        // Creates clean variables
        this.chosenValues.subcat.push("");
        for(let i=0; i< this.options.length; i++)
        {
            let currentOption = this.options[i];
            currentOption.options = [];
            this.chosenValues[`${currentOption.filterQueryTarget}`] = [];
        }
        // Creates firebase refs
        for(let i=0;i<this.arrayOfRef.length;i++)
        {
            this.refs.push(db.doc('Products/' + this.arrayOfRef[i]).get())
        };
        const result = await Promise.all(this.refs);
        for(let i=0;i<result.length;i++)
        {
            let data = result[i].data();
            if(data === undefined)
            {
                // If Data doesnt exsist clean up for next reload use;
                db.collection("Categories").doc(this.category).update({
                productsID: firebase.firestore.FieldValue.arrayRemove(result[i].id)
                }).catch((error)=>
                {
                    console.log(error);
                });
            }
            else
            {
                // Here goes normal way
                if(data.active && data.category === this.category)
                {
                    this.products.push(data);
                    // Check for max price
                    if(parseFloat(data.basePrice) > 0 && parseFloat(data.basePrice) > this.categoryMaxPrice)
                    {
                        this.categoryMaxPrice = parseFloat(data.basePrice).toFixed(2);
                        this.chosenValues.maxPrice = parseFloat(data.basePrice).toFixed(2);
                    }

                    // Add subcategory if doesnt contain in subcategory array
                    if(!this.subcategories.includes(data.subcategory))
                    {
                        if(data.subcategory.length>0)
                        {
                            this.subcategories.push(data.subcategory);
                            this.chosenValues.subcat.push(data.subcategory);
                        }

                    }

                    // Check if option doesnt include this product specs value
                    for(let y=0;y<this.options.length;y++)
                    {
                        let currentOption = this.options[y];
                        const value = eval(`data.${currentOption.filterQueryTarget}`);
                        if(value !== undefined)
                        {
                            if(!currentOption.options.includes(value))
                            {
                                currentOption.options.push(value);
                                if(this.chosenValues[currentOption.filterQueryTarget] !== undefined)
                                {
                                    this.chosenValues[currentOption.filterQueryTarget].push(value);
                                }
                                else
                                {
                                    this.chosenValues[currentOption.filterQueryTarget] = [];
                                    this.chosenValues[currentOption.filterQueryTarget].push(value);
                                }
                                currentOption.options.sort();
                            }
                        }
                    }
                }
            }

        }
        if(this.activeData !== null)
        {
            this.chosenValues = this.activeData.currentValues;
        }

        this.filterProductCount();
        setTimeout(()=>
        {
            this.loading = false;
        },1000)
    },
    focusInput(object){
        this.$refs[object].focus()
    },
    closeModal(){
        this.$emit('close');
      }
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/variables";
.checkbox {
  width: 15px;
  height: 15px;
  position: relative;
  label {
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%);
    border-radius: 4px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    transition: all 0.2s ease-in-out;
    &:after {
      content: '';
      width: 7px;
      height: 4px;
      position: absolute;
      top: 3px;
      left: 3px;
      border: 2px solid $red;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
      transition: all 0.2s ease-in-out;
    }
    &:hover::after {
      opacity: 0.2;
    }
  }
  label:hover{
      background: #ffffff;
    // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }    
}

.modal{
    width: 22.737rem;
    height: 100vh;
    // padding: 1.563rem;
    background: white;
    animation: fadeInRight 0.5s ease-in-out;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &__close{   
        z-index: 1;
        padding-top: 1.563rem;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        display: flex;
        height: 4.126rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid #ececec;
        span{
            font-size: 1rem;
            margin: 0;
            padding: 0;
            margin-right: auto;
            height: 1.563rem;
        }
        i{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        font-size: 1rem;
        width: 1rem;
        height: 1rem;
        color: black;
        transition: all 0.2s ease-in-out;
        }
        i::before{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1rem;
            height: 1rem;
        }
        i:hover{
            color: $red;
            cursor: pointer;
        }
    }
    &__loading
    {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow-y: auto !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        height: calc(100% - 4.126rem - 4.563rem);
    };

    &__filters{
        display: flex;
        flex-direction: column;
        height: calc(100% - 4.126rem - 4.563rem);
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow-y: auto !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__category{
            margin-bottom: 1rem;
            &__name{
                display: flex;
                user-select: none;
                flex-direction: column;
                margin-bottom: 0.21rem;
                h4{
                    padding: 0;
                    margin: 0;
                    font-size: 0.8rem;
                    font-weight: 500;
                    line-height: 1.25rem;
                }
                span{
                    user-select: none;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #6e6e6e;
                    cursor: pointer;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    transition: all 0.2s ease-in-out;
                }
                span:hover{
                    user-select: none;
                    color: $red;
                }
            }
                &__noData
                {
                    user-select: none;
                    font-size: 0.8rem;
                    opacity: 0.4
                }

                &__filter{
                display: flex;
                align-items: center;
                margin-top: 0.21rem;
                span{
                    user-select: none;
                    font-size: 0.8rem;
                    margin-left: 0.41rem;
                    padding-top: 1px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                    span{
                        user-select: none;
                        font-size: 0.64rem;
                        margin: 0;
                        color: #6e6e6e;
                    }
                }
                span:hover{
                    user-select: none;
                    color: $red;
                    span{
                        color: $red;
                    }
                }
            }
            &__priceBox{
                display: flex;
                align-items: center;
                height: 1.953rem;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin-top: 0.41rem;
                &__price{
                    display: flex;
                    align-items: center;
                    padding-left: 0.41rem;
                    padding-right: 0.41rem;
                    // border: 1px solid #333;
                    border-radius: 5px;
                    height: 100%;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background: #ffffff;
                    // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%);
                    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
                    cursor: text;
                    transition: all 0.3s ease-in-out;
                    span{
                        font-size: 0.8rem;
                        font-weight: 400;
                    }
                    input{
                        width: 3.815rem;
                        background: none;
                        // height: calc(100%-2px);
                        height: 100%;
                        border: none !important;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        font-family: 'Poppins', sans-serif !important;
                    }
                    input:focus{
  outline-style: none;
  box-shadow: none;
  border-color: none;
  background-color: none;
  color: none;

                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                
                }
                &__price:hover{
                    background: #ffffff;
                    // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
                    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
                }
                span{
                    margin-left: 0.41rem;
                    margin-right: 0.41rem;
                }
            }
            &__select{
                background: #ffffff;
                // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%) !important;
                box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5) !important;
                border-radius: 5px;
                margin-top: 0.21rem;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                select{
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    height: 1.953rem;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    font-family: 'Poppins', sans-serif !important;
                    font-size: 0.8rem;
                    padding: 0.21rem;
                    border: none;
                    outline-style: none;
                    box-shadow: none;
                    border-color: none;
                    background-color: none;
                    background: #ffffff;
                    color: none;
                    color: black;
                    border-radius: 5px;
                }
            }
            &__select:hover{
                background: #ffffff;
                // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
                box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
            }
        }
        &__category:last-child{
            margin: 0;
        }
        
    }
    &__save{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 9999 !important;
        height: 4.563rem;
        width: 100%;
        background: pink;
        padding-top: 1rem;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-bottom: 1rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid #ececec;
        background: #f7f7f7;
        button{
            cursor: pointer;
            padding: 0.64rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border: none;
            border-radius: 5px;
            font-size: 0.8rem;
            font-weight: 400;
            border: 1px solid #d1d1d1;
        }

        button:hover
        {
            opacity: 0.6;
        }
    }
}
.hide{
    display: none;
}

.saveButton{
    margin-left: 0.64rem;
}

.backdrop {
    z-index: 1001;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@keyframes fadeInRight {
  from {
    opacity: 1;
    transform: translateX(650px);
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 500px) {
    
    .modal{
        width: 100vw;
        height: 100%;
        &__filters{
            &__category{
                &__name{
                    span{
                        margin-top: 0.21rem;
                        margin-bottom: 0.21rem;
                    }
                }
            }
        }
        }
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 5px solid $red; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
