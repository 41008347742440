<template>
    <div class="emptyState">
        <div class="emptyState__header">
            Brak wyników.
        </div>
        <div class="emptyState__subtext">
            Niestety nie znaleźliśmy tego czego szukasz.
        </div>
        <div class="emptyState__image">
            <img src="../assets/emptyState.png" alt="Outlet komputerowy oferuje tanie zestawy komputerowe oraz najtańsze laptopy uzywane. W promocji na komputery znajdziesz równiez najtansze monitory full hd oraz specjalistyczne urządzenia all in one (aio)."> 
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
        },
        beforeCreate()
        {

        }
    }
</script>
<style lang="scss" scoped>
    // Color Variables Import
    @import '../styles/variables';

    .emptyState
    {
        width: 100vw;
        height: 85vh;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;

        &__header
        {
            font-size: 1.563rem;
            font-weight: 500;
            opacity: 0.5;
        }

        &__subtext
        {
            font-size: 1.25rem;
            opacity: 0.5;
        }

        &__image
        {
            margin-top: 1em;
            opacity: 0.1;

            img
            {
                width: 80%;
                height: auto;
            }
        }
    }


</style>